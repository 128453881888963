export default {
  clientId: [
    { required: true, message: 'Selecciona un cliente existente o crea uno nuevo' },
  ],
  orderDevices: [{
    type: 'array',
    required: true,
    validator(rule, value, callback) {
      if (value.length <= 0)
        callback();
      for (const device of value) {
        if (!device.deviceModelId)
          callback(new Error('Captura el modelo del dispositivo'));
        for (const service of device.orderDeviceServices) {
          if (service.cost === null || service.cost === undefined || service.cost === '' || service.cost < 0)
            callback(new Error(`El costo debe ser 0 o un número mayor para continuar - '${service.serviceCategory?.name || service.name}'`));
        }
        for (const product of device.orderDeviceProducts) {
          if (product.cost === null || product.cost === undefined || product.cost === '' || product.cost < 0)
            callback(new Error(`El costo debe ser 0 o un número mayor para continuar - '${product.productCategory?.name || product.name}'`));
        }
        callback();
      }
    },
  }],
};
