// const buildOrderDeviceProduct = () => ({ });

const buildOrderProduct = () => ({
  quantity: 0,
  productCategory: null,
});

const buildOrderDeviceService = () => ({
  id: null,
  orderId: null,
  orderDeviceId: null,
  serviceId: null,
  serviceType: null,
  serviceName: null,
  serviceCost: null,
  servicePrice: null,
  serviceCategory: null,
});

const buildOrderDevice = () => ({
  id: null,
  orderId: null,
  deviceType: null,
  deviceTypeId: null,
  deviceModel: null,
  deviceModelId: null,
  imei: null,
  deviceLockType: 1,
  passcode: null,
  pattern: null,
  hasDiagnostic: true,
  diagnosticNotes: null,
  hasWarranty: false,
  warrantyDays: 0,
  orderDeviceServices: [],
  orderDeviceProducts: [],
});

const buildOrder = () => ({
  id: null,
  clientId: null,
  createdByAccountId: null,
  assignedToAccountId: null,
  priority: null,
  dueDate: null,
  // advanceDescription: null,
  // advanceAmount: null,
  subtotalAmount: null,
  totalAmount: null,
  status: null,
  createdAt: null,
  updatedAt: null,
  authorizedByClient: false,
  orderDevices: [],
  client: null,
  taxRate: null,
  taxAmount: null,
  taxDescription: null,
  images: [],
  orderAdvances: [],
  orderProducts: [],
  orderTaxes: [],
});

export default {
  // buildOrderDeviceProduct,
  buildOrderProduct,
  buildOrderDeviceService,
  buildOrderDevice,
  buildOrder,
};
