import Vue from 'vue'
import Schema from 'async-validator'
import rules from './rules'
import builders from './builders'
import checklistItemsState from '@/store/modules/checklist-items/state';

const state = new Vue.observable({
  order: { ...builders.buildOrder() },
  client: null,
  errors: [],
});

const getters = {
  getOrderId: () => state.order.id,
  orderDevicesCount: () => state.order.orderDevices?.filter(device => device.deviceModelId != null).length,
  orderProductsCount: () => state.order.orderProducts?.filter(product => product.productId !== null).length,
  getServices: () => {
    const services = [];
    for (let i = 0; i < state.order.orderDevices.length; i++) {
      const orderDevice = state.order.orderDevices[i];
      for (let i = 0; i < orderDevice.orderDeviceServices.length; i++) {
        services.push({
          id: orderDevice.orderDeviceServices[i].id,
          type: 'Servicio',
          name: orderDevice.orderDeviceServices[i].name,
          description: orderDevice.orderDeviceServices[i].description,
          price: orderDevice.orderDeviceServices[i].price,
        });
      }
      for (let i = 0; i < orderDevice.orderDeviceProducts.length; i++) {
        services.push({
          id: orderDevice.orderDeviceProducts[i].id,
          type: 'Producto',
          name: orderDevice.orderDeviceProducts[i].name,
          description: orderDevice.orderDeviceProducts[i].description,
          price: orderDevice.orderDeviceProducts[i].price,
        });
      }
    }
    return services;
  },
  getSubtotal: () => {
    let sum = 0;
    state.order.orderDevices?.forEach(device => {
      const totalOrderDeviceServices = device.orderDeviceServices?.reduce((accumulator, service) => {
        return parseFloat(accumulator || 0) + parseFloat(service.price || '0');
      }, 0);
      const totalOrderDeviceProducts = device.orderDeviceProducts?.reduce((accumulator, product) => {
        return parseFloat(accumulator || 0) + (parseFloat(product.price || '0') * (parseFloat(product.quantity || '1')));
      }, 0);
      sum = sum + totalOrderDeviceServices + totalOrderDeviceProducts
    });
    state.order.orderProducts?.forEach(product => {
      sum = sum + (parseFloat(product.price) * (parseFloat(product.quantity) || 1));
    });
    state.order.taxAmount = sum * (state.order.taxRate / 100);
    state.order.subtotalAmount = sum;
    return state.order.subtotalAmount;
  },
  getTax: () => {
    return {
      taxRate: state.order.taxRate,
      taxAmount: getters.getSubtotal() * (state.order.taxRate / 100),
      taxDescription: state.order.taxRate ? `${state.order.taxDescription} ${state.order.taxRate}%` : '',
    };
  },
  getAdvance() {
    return state.order.orderAdvances.reduce((acc, curr) => acc + curr.advanceAmount, 0);
  },
  getTotal() {
    const totalAmount = state.order.subtotalAmount
      + state.order.taxAmount
      - state.order.orderAdvances.reduce((acc, curr) => acc + curr.advanceAmount, 0);
    state.order.totalAmount = totalAmount;
    return totalAmount;
  },
  getFinalizedStatusAt() {
    return state.order.finalizedStatusAt;
  },
};

const mutations = {
  resetState: () => {
    Object.assign(state, {
      order: { ...builders.buildOrder() },
      client: null,
      errors: [],
    })
  },
  addOrderDevice: () => {
    state.order.orderDevices.push({ ...builders.buildOrderDevice() })
  },
  removeOrderDevice: (orderIndex) => {
    state.order.orderDevices.splice(orderIndex, 1)
  },
  addOrderDeviceService(orderDeviceIndex, service) {
    state.order.orderDevices[orderDeviceIndex].orderDeviceServices.push({ ...service, cost: 0, price: 0 });
  },
  removeOrderDeviceService(orderDeviceIndex, orderDeviceServiceIndex) {
    state.order.orderDevices[orderDeviceIndex].orderDeviceServices.splice(orderDeviceServiceIndex, 1)
  },
  addOrderDeviceProduct(orderDeviceIndex, product) {
    state.order.orderDevices[orderDeviceIndex].orderDeviceProducts.push(product);
  },
  removeOrderDeviceProduct(orderDeviceIndex, orderDeviceProductIndex) {
    state.order.orderDevices[orderDeviceIndex].orderDeviceProducts.splice(orderDeviceProductIndex, 1)
  },
  addAdvance(advance) {
    state.order.orderAdvances.push({
      id: advance.id,
      advanceDescription: advance.advanceDescription,
      advanceAmount: advance.advanceAmount,
      advancePaymentMethod: advance.advancePaymentMethod,
    });
  },
  changeAdvance(advance) {
    state.order.orderAdvances = state.order.orderAdvances
      .map((orderAdvance) => orderAdvance.id === advance.id ? advance : orderAdvance);
  },
  removeAdvance(advanceId) {
    state.order.orderAdvances = state.order.orderAdvances
      .filter((advance) => advance.id !== advanceId);
  },
  changeTax(tax) {
    state.order.taxRate = parseFloat(tax.taxRate);
    state.order.taxAmount = state.order.subtotalAmount * (state.order.taxRate / 100);
    state.order.taxDescription = tax.taxDescription || '';
  },
  changePriority(priority) {
    state.order.priority = priority;
  },
  changeDueDate(dueDate) {
    state.order.dueDate = dueDate;
  },
  changeUser(user) {
    state.order.assignedToAccountId = user;
  },
  pushImage(image_path) {
    state.order.images = [...state.order.images, image_path];
  },
  filterImage(image_path) {
    state.order.images = state.order.images.filter((i) => i !== image_path);
  },
  changeDevicePattern(orderDeviceIndex, pattern) {
    for (let i = 0; i < state.order.orderDevices.length; i++) {
      state.order.orderDevices[i].pattern = pattern;
    }
  },
  addOrderProduct(product) {
    state.order.orderProducts = [...state.order.orderProducts, product];
  },
  removeOrderProduct(productId) {
    state.order.orderProducts = state.order.orderProducts.filter((product) => product.id !== productId);
  },
  changeApplicableTaxes(taxes) {
    const taxesIds = taxes.map((tax) => tax.id);
    state.order.orderTaxes = taxesIds;
    
  }
};

const actions = {
  validateOrder: () => {
    return new Schema(rules).validate(state.order, errors => {
      state.errors = errors ?? []
    });
  },
};

const mappers = {
  mergeChecklistItems: (checklistItems) => {
    return checklistItemsState.entities.map((checklistItem) => {
      const deviceItem = checklistItems.find((c) => c.checklistId === checklistItem.id);
      return {
        checklistId: checklistItem.id,
        id: deviceItem?.id || undefined,
        name: checklistItem.name,
        value: deviceItem?.value || false,
      }
    });
  },
  mapGetResponse: (order) => ({
    ...order,
    images: order.images.map((image) => image.replace(`${process.env.VUE_APP_DOCUMENTS_SAMII}/`, '')),
    orderDevices: order.orderDevices.map((od) => ({
      ...od,
      deviceModel: {
        brand: od.deviceBrand,
        deviceType: od.deviceType,
        id: od.deviceModelId,
        model: od.deviceModel,
      },
      orderDeviceServices: od.orderDeviceServices.map((ods) => ({
        ...ods,
        name: ods.serviceCategory?.name ?? ods.name,
      })),
      checklistItems: mappers.mergeChecklistItems(od.checklistItems),
      deviceTypeId: od.deviceType.id,
      hasDiagnostic: true,
    })),
    signals: {
      paid: order.paid,
      warranty: order.warranty,
    },
    orderAdvances: order.orderAdvances.map((oa) => ({ ...oa, advancePaymentMethod: oa.paymentMethodId })),
    orderTaxes: order.orderTaxes,
    signature: order.signature ? `${process.env.VUE_APP_S3_BUCKET}/${order.signature}` : null,
  }),
  mapPostRequest: () => ({
    ...state.order,
    images: state.order.images,
    orderDevices: state.order.orderDevices.map((od) => ({
      ...od,
      orderDeviceServices: od.orderDeviceServices.map((ods) => ({
        serviceId: ods.id,
        description: ods.description,
        cost: parseFloat(ods.cost),
        price: parseFloat(ods.price),
      })),
      orderDeviceProducts: od.orderDeviceProducts.map((odp) => ({
        productId: odp.id,
        quantity: parseFloat(odp.quantity),
        cost: parseFloat(odp.cost),
        price: parseFloat(odp.price),
      })),
      warrantyDays: od.hasWarranty ? od.warrantyDays : 0,
      diagnosticNotes: od.diagnosticNotes,
      checklistItems: od.checklistItems.map((checklistItem) => ({
        id: checklistItem.id,
        checklistId: checklistItem.checklistId,
        value: checklistItem.value,
      })),
      hasDiagnostic: true,
    })),
    orderAdvances: state.order.orderAdvances.map((advance) => ({
      id: advance.id[0] === '~' ? undefined : advance.id,
      advanceDescription: advance.advanceDescription,
      advanceAmount: advance.advanceAmount,
      paymentMethodId: advance.advancePaymentMethod,
    })),
    orderProducts: state.order.orderProducts.map((op) => ({
      productId: op.id,
      quantity: parseFloat(op.quantity),
      cost: parseFloat(op.cost),
      price: parseFloat(op.price),
    })),
  }),
};

export {
  state,
  getters,
  actions,
  mutations,
  mappers,
};
